.contact-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .contact {
    margin-top: 25px;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
  }
  .info {
    margin-bottom: 10px;
    display: flex;
  }
  .info-data {
    margin: 3px 6px ;
  }
  .location-icon {
    margin-top: 7px;
  }

  .form-data {
    display: flex;
    flex-direction: column;
  }
  .additional-info {
    width: 25%;
  }
  .additional-info span {
    margin-bottom: 7px;
  } 
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding-left: 25px;
  }
  
  .contact-form label {
    margin-bottom: 5px;
  }

  .form-message {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 15px;
  }
  .contact-form textarea{
    height: 280px;
  }
  .contact-form button {
    padding: 10px;
    margin-bottom: 25px;
    background-color: var(--first-color);
    color: var(--body-color);
    border-radius: 15px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: var(--body-color);
    color: var(--first-color);
    border-style: solid;
    border-color: var(--first-color);
    border-radius: 15px;
  }



  @media (max-width: 945px) {
    .contact-form {
        width: 68%; 
        
      }
    .additional-info {
        width: 32%;
      }
  }
  
  @media (max-width: 768px) {
    .contact-container {
      max-width: 100%;
      padding: 0 15px;
    }
    .contact {
        display:flex;
        flex-direction: column;
        justify-content: center;
      }
    .contact-form {
        width: 100%; 
        padding-left: 0px;
        margin-top: 15px;
      }
    .additional-info {
        width: 100%;
      }
    .contact-form input,
    .contact-form textarea {
      width: 100%;
    }
  }
  