/* Home.css */

.slider-item {
    position: relative;
    width: 100%;
}

.slider-item img {
    width: 100%;
    height: 69.9vh;
    margin: 0;
    padding: 0;
}

.slider-content-1,
.slider-content-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--first-color);
    text-align: center;
    background-color: var(--body-color);
    padding: 25px 25px;
    border-radius: 15px;
}

.slider-content-1 h1 {
    font-size: 2rem;;
}
.slider-content-1 h2,
.slider-content-2 h2 {
    font-size: 1.5rem;
}

.slider-content-1 p,
.slider-content-2 p {
    font-size: 1rem;
    margin-top: 15px;
}

.slider-content-2 button {
    background-color: var(--first-color);
    margin-top: 15px;
    border: 2px solid white;
    border-radius: 15px;
    color: var(--body-color);
    padding: 10px 20px;
    font-size: 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.slider-content-2 button:hover {
    background-color: var(--body-color);
    color: var(--first-color);
    border: 2px solid var(--first-color);
}

.custom-prev-arrow,
.custom-next-arrow {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1; /* Ajout de z-index */
}

.custom-prev-arrow {
    left: 0;
    transform: translateY(-50%);
}

.custom-next-arrow {
    right: 0;
    transform: translateY(-50%);
}

.custom-prev-arrow:hover,
.custom-next-arrow:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Styling pour les slick-dots */
.slick-dots {
    list-style: none; /* Supprime les puces */
    text-align: center; /* Centre les points */
    position: absolute;
    bottom: 10px; /* ou une valeur appropriée */
    z-index: 999; /* ou tout autre valeur supérieure au z-index du footer */
}

/* Styling pour chaque point individuel */
.slick-dots li {
    display: inline-block;
    margin: 0 5px; /* Espacement entre les points */
}

/* Style du point actif */
.slick-dots > button {
    font-size: 40px; /* Taille du point */
    color: var(--first-color); /* Couleur du point */
}

/* Style du point inactif */
.slick-dots > button:hover {
    color: #999; /* Couleur du point au survol */
}

/* Media query for responsive design */
@media (max-width: 768px) {
    .slider-content-1 h1 {
        font-size: 1.4rem;
    }

    .slider-content-1 h2,
    .slider-content-2 h2 {
        font-size: 1rem;
    }

    .slider-content-1 p,
    .slider-content-2 p {
        font-size: 0.7rem;
    }

    .slider-content-2 button {
        font-size: 1rem;
    }
    .custom-prev-arrow,
    .custom-next-arrow {
        width: 25px;  /* Further adjust width for smaller screens */
        height: 25px; /* Further adjust height for smaller screens */
        font-size: 14px; /* Further adjust font size for smaller screens */
    }
    .slider-content-1,
    .slider-content-2  {
    padding: 15px 15px;
    border-radius: 15px;
    }

    .slider-content-1 p{
        overflow: hidden; /* Cache le texte dépassant de la zone */
        text-overflow: ellipsis; /* Ajoute des points de suspension pour indiquer qu'il y a du texte caché */
        display: -webkit-box;
        -webkit-line-clamp: 4; /* Limite à deux lignes */
        -webkit-box-orient: vertical;
    }
    .slider-content-1:hover p {
        -webkit-line-clamp:inherit; /* Affiche toutes les lignes */
    }
}