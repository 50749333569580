.footer {
  width: 100%;
  height: 150px;
  background-color: var(--first-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0;
  margin-top: 0;
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.footer p {
  color: white;
}

/* Media query pour les petits écrans */
@media screen and (max-width: 768px) {
  .footer {
    height: auto; /* Hauteur automatique pour s'adapter au contenu */
    padding: 20px; /* Ajoute un peu d'espacement autour du contenu */
  }

  .socialMedia svg {
    font-size: 40px; /* Réduit la taille des icônes pour les petits écrans */
    margin: 10px; /* Réduit l'espacement entre les icônes pour les petits écrans */
  }
}
