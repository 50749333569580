/* services.css */

.serviceList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Ajuste l'espacement entre les cartes */
}

.serviceItem {
    position: relative; /* Ajoute une position relative pour pouvoir positionner les éléments internes */
    width: calc(33.33% - 20px); /* Pour afficher 3 cartes par ligne avec un espacement entre chaque carte */
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Animation de transition */
}

.serviceItem:hover {
    transform: translateY(-5px); /* Lève légèrement la carte au survol */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Ajoute une ombre plus prononcée au survol */
}

.serviceItem > div {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
    border-radius: 8px;
}

.serviceItem h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.serviceItem p {
    font-size: 1rem;
    color: #666;
    overflow: hidden; /* Cache le texte dépassant de la zone */
    text-overflow: ellipsis; /* Ajoute des points de suspension pour indiquer qu'il y a du texte caché */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limite à deux lignes */
    -webkit-box-orient: vertical;
}

/* Affichage complet au survol */
.serviceItem:hover p {
    -webkit-line-clamp:inherit; /* Affiche toutes les lignes */
}

/* Media query pour les écrans de taille inférieure à 768px (petits écrans) */
@media screen and (max-width: 768px) {
    .serviceList {
        flex-direction: column; /* Affiche les cartes de service en colonnes */
        align-items: flex-start; /* Aligne les cartes de service sur le côté gauche */
    }

    .serviceItem {
        width: calc(100% - 20px); /* Occupe toute la largeur de la colonne avec un espacement entre chaque carte */
    }
}

