.navbar {
    background-color: var(--body-color);
    color: var(--body-color);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .navbar-logo img{
    width: 140px; /* ajustez selon votre logo */
    padding: 15px 20px;
  }
  
  .navbar-links {
    display: flex;
  } 
  
  .navbar-link {
    color: var(--first-color);
    text-decoration: none;
    padding: 20px 20px;
    transition: color 0.3s ease; /* Transition de la couleur lors du survol */
  }
  
  .navbar-link:hover,
  .navbar-link.active {
    color: var(--body-color);
    background-color: var(--first-color);
  }
  
  .navbar-toggle {
    cursor: pointer;
    display: none;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: all 0.3s ease;
  }
  
  .active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);

  }
  
  .active .bar:nth-child(2) {
    opacity: 0;
  }
  
  .active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  @media screen and (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 52px; /* Ajustement de la position vers le haut */
      left: 0;
      width: 100%;
      background-color: var(--body-color);
      color: var(--first-color);
      text-align: center;
      z-index: -1; /* Permet de passer le survol à travers le menu déroulant */
    }
  
    .navbar-links.active {
      display: flex;
      z-index: 2; /* Met en avant le menu déroulant */
    }
  
    .navbar-link {
      display: block;
    }
  
    .navbar-toggle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 20px;
      color: var(--first-color);
    }
  
    .navbar-toggle.active .bar:nth-child(1) {
      transform: rotate(-45deg) translate(-4px, 6px);
    }
  
    .navbar-toggle.active .bar:nth-child(2) {
      opacity: 0;
    }
  
    .navbar-toggle.active .bar:nth-child(3) {
      transform: rotate(45deg) translate(-4px, -6px);
    }
  }
  